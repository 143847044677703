import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UILIB from 'components';
import Axios from 'classes/axios';
import { checkFormErrors } from '../../../../classes/formErrors/formErrors';
import { getDeviceMovementFormErrors, getDeviceMovementQuoteErrors } from '../../../../classes/formErrors/repository';
import BlockDetailPage from './tabs/blockDetailPage';
import BlockDevicePage from './tabs/blockDevicePage';
import BlockQuotePage from './tabs/blockQuotePage';
import { getLogisticsUsers } from "classes/helpers";


export default function DeviceMovement() {

  const { formid } = useParams()
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [logisticsUsers, setLogisticsUsers] = useState([])
  const [existingform, setExistingform] = useState(false)
  const [currentTab, setCurrentTab] = useState(0);
  const [submitted, setSubmitted] = useState(false)
  const [formData, setFormData] = useState({
    devices: [],
    decomRequired: false, 
    macService: false, 
    printManagerSystem: false,
    requiresNetwork: "Yes", 
    requiresMoveDate: true,
    chargeable: true,
    parkingOptions: "Not Required",
    processStage: 0,
    collectionAddress: '',
    collectionEmail: '',
    collectionName: '',
    collectionTelephone: '',
    companyName: '',
    companyEmailL: '',
    companyTelephone: '',
    contactEmailNetwork: '',
    contactNameNetwork: '',
    contactTelephoneNetwork: '',
    courier: '',
    delAddress: '',
    delContactEmail: '',
    delContactName: '',
    delContactTelephone: '',
    delMethod: '',
    moveDate: '',
    salesPerson: '',
    serviceRegion: '',
    logisticsPoCId: -1,
    psOther: '',
    companyEmailAddress: '',
    companyContactName: '',
    totalValue: '',
    companyEmail: ''
  })
  const [tabErrors, setTabErrors] = useState([]);
  const [formErrors, setFormErrors] = useState(getDeviceMovementFormErrors(formData));
  const [quoteErrors, setQuoteErrors] = useState(getDeviceMovementQuoteErrors(formData))


  const clearErrors = () => {
    setTimeout(() => {
      const formErrorsNew = {...formErrors};
      const quoteErrorsNew = {...quoteErrors};
      setFormErrors(Object.values(formErrorsNew).forEach(e => { e.error = false; e.option = 0 }));
      setQuoteErrors(Object.values(quoteErrorsNew).forEach(e => { e.error = false; e.option = 0 }));
      setTabErrors([]);
    }, 500)
  }

  const submitForm = async (formData, hist, opts, subType)  => {

      let formErrorsNew = JSON.parse(JSON.stringify(formErrors));
      let foundDeviceErrors = false;
      let errorDeviceCnt = 0
      let quoteErrorCnt = 0;
      let quoteTotalCnt = 0;

      formErrorsNew[formErrorsNew.findIndex((obj => obj.name === "moveDate"))].disabled = !formData.requiresMoveDate;

      const formResult = await checkFormErrors(formErrorsNew, setFormErrors, tabErrors, setTabErrors, formData, null);

      if(formData.processStage >= 3) {
        const quoteResult = await checkFormErrors(quoteErrors, setQuoteErrors, tabErrors, setTabErrors, formData, null);
        quoteErrorCnt = quoteResult.errorCount;
        quoteTotalCnt = quoteResult.totalCount;
      }
      
      formData.devices.forEach(i => { if(!i.ticked) errorDeviceCnt++ });
      if((errorDeviceCnt === formData.devices.length) || formData.devices.length === 0) {
        foundDeviceErrors = true;
        tabErrors[1] = 1;
        setTabErrors([...tabErrors]);
      }

      if (formResult.errorCount === 0 && quoteErrorCnt === 0 && !foundDeviceErrors ) {

          setSubmitted(true); 

          const hostData = {
            hostname: window.location.host,
            protocol: window.location.protocol
          }
          formData.hostName = hostData.hostname;

          switch (opts) {
            case 0:  
              Axios.post(`/logistics/deviceMovement/${subType === 1 ? 'saveExistingForm' : 'saveNewForm'}`, [formData, subType === 1 ? formData.processStage : 1, false, null]).then(() => {
                hist.push({ pathname: '/logisticsHub',  state: { tab: history.location.state.tab } })
              })
              break;
            case 1:  
              Axios.post(`/logistics/deviceMovement/${subType === 1 ? 'saveExistingForm' : 'saveNewForm'}`, [formData, formData.chargeable === true ? 3 : 2, true, hostData],).then(() => {
                hist.push({ pathname: '/logisticsHub',  state: { tab: history.location.state.tab } })
              })
              break;
            case 2:
              Axios.post(`/logistics/deviceMovement/generateQuote?id=${formData.id}`, [formData, hostData]).then(() => {
                hist.push({ pathname: '/logisticsHub', state: { tab: history.location.state.tab } })
              })
              break;
            default:
              break;
          }
      }
      else {
          if(formResult.errorCount > 0) toast.error(formResult.errorCount + "/" + formResult.totalCount + " form fields require attention before saving");
          if(quoteErrorCnt > 0) toast.error(quoteErrorCnt + "/" + quoteTotalCnt + " quote fields require attention before saving");
          if(foundDeviceErrors) toast.error("Please select at least one device to move before saving");
      }
  }


  useEffect(() => {  
    (async () => {
      setLoading(true);
      let formDataNew = null;
      let formDat = null;
      let existingLocal = history.location.state && history.location.state.existingForm ? true : false;
      let existingRemote = formid ? true : false;

      const users = await getLogisticsUsers();
      const logisticsUsersNew = JSON.parse(JSON.stringify(users));
      logisticsUsersNew.unshift({ label: "Please Select", value: "" });
      setLogisticsUsers(logisticsUsersNew);

      switch(true) { 
          case existingLocal:  
            formDat = await Axios.get(`/logistics/deviceMovement/getFormData?rowId=${history.location.state.uuid}`);
            if(formDat.data.data === null) history.push('/viewLinkError');
            formDataNew = JSON.parse(JSON.stringify(formDat.data.data));
            setExistingform(history.location.state.existingForm);
        break;
          case existingRemote:  
            formDat = await Axios.get(`/logistics/deviceMovement/getFormData?rowId=${formid}`);
            if(formDat.data.data === null) history.push('/viewLinkError');
            formDataNew = JSON.parse(JSON.stringify(formDat.data.data));
            setExistingform(true);
            history.replace({ state: { tab: 0 } });
        break;
          case (!existingLocal && !existingRemote):
            formDataNew = JSON.parse(JSON.stringify(formData));
        break;
        default:
          break;
      }   
      if(formDataNew.processStage === 3) {
        formDataNew.quote.push({
          totalValue: null,
          quoteValueNotes: null,
          companyEmailAddress: null,
          companyContactName: null,
          companyEmailNotes: null
        });
      }
      setFormData(formDataNew);
      setLoading(false);  
    })()
  }, [])



  const tabs = [
    { label: "Details", value: 0 }, 
    { label: "Devices", value: 1 }, 
    { label: "Quote", value: 2, disabled: formData.processStage < 2 ? true : false } 
  ]



  if (loading) {
    return (
      <div style={{position: 'absolute', left: '50%', top: '20%',transform: 'translate(-50%, -50%)'}}>
        <div className="col center"><h2>Loading, Please wait</h2>
          <UILIB.Loading type={3} />
        </div>
      </div>
    )
  }


  return <div className="row">
      <ToastContainer
        progressClassName="toastify"
        position="bottom-right"
        autoClose={5000}
        style={{ width: 500 }}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="col-xs-12 col-sm-3 col-md-3 mar-t15 flex-container">
        <UILIB.Button 
          className="white outline" 
          value="Back" 
          iconBefore="icon-arrow-left" 
          onClick={() => history.push({
            pathname: '/logisticsHub', 
            state: {
              tab: history.location.state.tab
            }
          })}  
        />
      </div>
      {!submitted && formData.processStage < 3 && <div className="col-xs-12 col-sm-9 col-md-9 mar-t15 mar-b15 flex-container end">
        <UILIB.Button className="mar-r15"
          value={"Save and Exit"}
          onClick={() => { submitForm(formData, history, 0, (!submitted && !existingform ? 0 : 1)) }} 
        />
        <UILIB.Button className="mar-r15"
          value={!submitted && !existingform ? "Save and send Email" : "Update and send Email"}
          onClick={() => { submitForm(formData, history, 1, (!submitted && !existingform ? 0 : 1)) }} 
        />
        <UILIB.Button className="mar-r15"
          disabled={true}
          value={"Submit Quote"}
        />  
      </div>}
      {!submitted && formData.processStage === 3 && <div className="col-xs-12 col-sm-9 col-md-9 mar-t15 mar-b15 flex-container end">
        <UILIB.Button className="mar-r15"
          disabled={true}
          value={"Save and Exit"}
          onClick={() => { submitForm(formData, history, 0, !submitted && !existingform ? 0 : 1) }} 
        />
        <UILIB.Button className="mar-r15"
          disabled={true}
          value={!submitted && !existingform ? "Save and send Email" : "Update and send Email"}
          onClick={() => { submitForm(formData, history, 1, !submitted && !existingform ? 0 : 1) }} 
        />
        <UILIB.Button className="mar-r15"
          value={"Submit Quote"}
          onClick={() => { submitForm(formData, history, 2, '') }} 
        />  
      </div>}
      {submitted && <div className="col-xs-12 col-sm-9 col-md-9 flex-container end">
        <UILIB.Button loading={true} />
      </div>}
      <div className="col-xs-12">
        <UILIB.SubNav 
          tabs={tabs} 
          currentTab={currentTab} 
          outerClasses="mar-t15 mar-b15" 
          onClick={(newId) => setCurrentTab(newId)} 
          errored={{ option: 1, tabs: tabErrors }}
        />
      </div>
      {currentTab === 0 &&
      <div className="col-xs-12 col-sm-12 col-md-12">
        <BlockDetailPage
          formData={formData}
          setFormData={setFormData}
          loading={loading}
          logisticsUsers={logisticsUsers}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          clearErrors={clearErrors}
        />
      </div>}
      {currentTab === 1 &&
      <div className="col-xs-12 col-sm-12 col-md-12">
        <BlockDevicePage
          formData={formData}
          setFormData={setFormData}
          loading={loading}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
        />
      </div>}
      {currentTab === 2 &&
      <div className="col-xs-12 col-sm-12 col-md-12">
        <BlockQuotePage
          formData={formData}
          setFormData={setFormData}
          loading={loading}
          quoteErrors={quoteErrors}
          clearErrors={clearErrors}
        />
      </div>}
  </div>
}


