import { Fragment } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DocumentTypes from './documentTypes';
import DocumentList from './documentList';


export default function DocumentsPage(props) {
    return <Fragment>
        <ToastContainer
          progressClassName="toastify"
          position="bottom-right"
          autoClose={5000}
          style={{ width: 500 }}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover />
        <div className={"col-xs-12 mar-b25 col-xs-12 "}>
            <DocumentTypes {...props} />
        </div>
        <div className={"col-xs-12 mar-b25 col-xs-12 "}>
            <DocumentList {...props} />
        </div>
    </Fragment>
}