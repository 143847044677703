import { Fragment, useState } from 'react';
import Axios from "classes/axios";
import UILIB from 'components';

export default function ViewNotes({ entity, setAddNote, setEditNote, sync, isException }) 
{  
  const defaultDialogue = { noteId: '', note: '', show: false };
  const headerText = isException ? 'exception' : 'device';
  const [deleting, setDeleting] = useState(false);
  const [showDialogue, setShowDialogue] = useState(defaultDialogue);

  const actionDeleteNote = async (noteId) => {
    setDeleting(true);
    await Axios.delete(`/entities/mifNote/${noteId}`);
    entity.notes = entity.notes.filter(x => x.id !== noteId);
    setShowDialogue(defaultDialogue);
    sync.set(!sync.get);
    setDeleting(false);
  };

  return <Fragment>
    {showDialogue.show && <UILIB.MessageBox 
      header={'Delete Records'} 
      loading={deleting}
      text={`Click OK to DELETE the selected note "${showDialogue.note}"`} 
      onConfirm={async () => await actionDeleteNote(showDialogue.noteId)} 
      onCancel={() => setShowDialogue({ ...showDialogue, show: false })} />}
    <div className={`flex-container row width-100`}>
      <div className={`flex-item flex-grow-1 end`}> 
        <UILIB.Button value={"Add Notes"} className="green" onClick={() => setAddNote(true)} />
      </div>
    </div>
    <div className={`flex-container row padding-4`}>
      {Boolean(!entity.notes || !entity.notes.length) && <div className="text-14 font-weight-bold">No notes available for this {headerText}.</div>}
      {Boolean(entity.notes && entity.notes.length) && <div className="width-100">
        <div className="text-14 font-weight-bold">Notes for {headerText} {entity.serialNumber}</div>
        {entity.notes.sort((a, b) => { return a.id - b.id }).map(note => {
          const createdBy = (note.createdByTxt) ? note.createdByTxt : '';
          const createdAt = (note.updatedAt) ? note.updatedAt : note.createdAt;
          return <UILIB.NoteContainer type={note.NoteSource} createdBy={createdBy} updatedAt={createdAt} message={note.note} noteId={note.id} setShowDialogue={setShowDialogue} setEditNote={setEditNote} /> })}
      </div>}
    </div>
  </Fragment>
}