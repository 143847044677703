import UILIB from 'components';
import { setDrawer } from 'store/actions';
import ViewNotesDrawer from './drawer/viewNotesDrawer';
const moment = require('moment-timezone');
const todayMinusFive = moment().subtract(5, 'days').format('YYYY-MM-DD');

export function getYesNo() {
    return [{ value: -1, label: "Select" },{ value: 0, label: "No" },{ value: 1, label: "Yes" }]
}

export function actionOpenNotesDrawer(type, entity, dispatch, enabled, sync, isException) {
    if(!enabled) return false;
    const selector = (type, data) => {
      if(type === 'ViewNotesDrawer') return <ViewNotesDrawer entity={data} onClose={() => dispatch(setDrawer({ open: false, content: null }))} sync={sync} isException={isException} /> 
    };
    dispatch(setDrawer({ 
      open: true, 
      width: "600px",
      content: selector(type, entity)
    }))
}

export function actionOpenExceptionsDrawer(type, entity, dispatch, enabled, sync) {
    if(!enabled) return false;
    const selector = (type, data) => {
      if(type === 'ViewNotesDrawer') return <ViewNotesDrawer entity={data} onClose={() => dispatch(setDrawer({ open: false, content: null }))} sync={sync} /> 
    };
    dispatch(setDrawer({ 
      open: true, 
      width: "600px",
      content: selector(type, entity)
    }))
}

export function tableContainer(onClick, icon, toolTip, colour, hover) {
    return <UILIB.TableContainer onClick={onClick} icon={icon} toolTip={toolTip} colour={colour} hover={hover} />
}

export function setFilterVars(context, filter) {
    if(filter.button === 'accountNumber') {
        return ` (accountNo like ${filter.accountNumber}) `;
    }
    if(filter.button === 'totalMif') {
        return ``;
    }
    if(filter.button === 'neverCommunicated') {
      return `mifexception is null and (lastScannedAnyDate is null)  `;
    }
    if(filter.button === 'totalCustomersImpacted') {
      return `mifexception is null and (lastScannedAnyDate lte ${todayMinusFive} or lastScannedAnyDate is null) `;
    }
    if(filter.button === 'ricohRemoteImpacted') {
      return `mifexception is null and (lastRemoteDate isn null and lastRemoteDate lte ${todayMinusFive} and lastKpaxDate is null and lastSiteAuditDate is null and LastHpDate is null and lastKonicaDate is null and lastEmDate is null and lastLeemicDate is null and lastPipsDate is null and lastOtherDate is null and lastCslDate is null and lastKdDate is null) `;
    }
    if(filter.button === 'konicaCsrcImpacted') {
      return `mifexception is null and (lastKonicaDate isn null and lastKonicaDate lte ${todayMinusFive} and lastKpaxDate is null and lastSiteAuditDate is null and LastHpDate is null and lastRemoteDate is null and lastEmDate is null and lastLeemicDate is null and lastPipsDate is null and lastOtherDate is null and lastCslDate is null and lastKdDate is null) `;
    }
    if(filter.button === 'canonEMainImpacted') {
      return `mifexception is null and (lastEmDate isn null and lastEmDate lte ${todayMinusFive} and lastKpaxDate is null and lastSiteAuditDate is null and LastHpDate is null and lastRemoteDate is null and lastKonicaDate is null and lastLeemicDate is null and lastPipsDate is null and lastOtherDate is null and lastCslDate is null and lastKdDate is null) `;
    }
    if(filter.button === 'totalMonitoredMif') {
      return `mifexception is null and (lastScannedAnyDate isn null) `;
    }
    if(filter.button === 'monitoredMifNotCommunicatedInLast5Days') {
      return `mifexception is null and (lastScannedAnyDate lte ${todayMinusFive}) `;
    }
    if(filter.button === 'monitoredMifConnectivityRate') {
      return `mifexception is null and (lastScannedAnyDate gt ${todayMinusFive}) `;
    }
    if(filter.button === 'totalDevicesImpacted') {
      return `mifexception is null and (lastScannedAnyDate lte ${todayMinusFive} or lastScannedAnyDate is null) `;
    }
    if(filter.button === 'kpaxImpacted') {
      return `mifexception is null and (lastKpaxDate isn null and lastKpaxDate lte ${todayMinusFive} and lastSiteAuditDate is null and LastHpDate is null and lastRemoteDate is null and lastKonicaDate is null and lastEmDate is null and lastLeemicDate is null and lastPipsDate is null and lastOtherDate is null and lastCslDate is null and lastKdDate is null) `;
    }
    if(filter.button === 'siteAuditImpacted') {
      return `mifexception is null and (lastSiteAuditDate isn null and lastSiteAuditDate lte ${todayMinusFive} and lastKpaxDate is null and LastHpDate is null and lastRemoteDate is null and lastKonicaDate is null and lastEmDate is null and lastLeemicDate is null and lastPipsDate is null and lastOtherDate is null and lastCslDate is null and lastKdDate is null) `;
    }
    if(filter.button === 'hpWjaImpacted') {
      return `mifexception is null and (LastHpDate isn null and LastHpDate lte ${todayMinusFive} and lastKpaxDate is null and lastSiteAuditDate is null and lastRemoteDate is null and lastKonicaDate is null and lastEmDate is null and lastLeemicDate is null and lastPipsDate is null and lastOtherDate is null and lastCslDate is null and lastKdDate is null) `;
    }
    if(context === 'impacted') {
      return `mifexception is null and (lastScannedAnyDate lte ${todayMinusFive} or lastScannedAnyDate is null) `; 
    }
    else if(context === 'neverCommunicated') {
      return `(neverCommunicated ne 0 or neverCommunicated is null) `;
    }
  }