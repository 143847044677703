import { useState, useRef, Fragment } from 'react';
import Axios from 'classes/axios';
import UILIB from 'components';
import { formatDateTime } from "classes/format";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setFilterVarsRegistration, getGridChip } from '../functions';


export default function RequestLookupTable() {
  const headerData = [
    { label: '', value: 'selected', type: '', filtering: false, minWidth: 30, maxWidth: 30 },
    { label: 'Company Name', value: 'customerName', type: 'string', filtering: true, minWidth: 300, maxWidth: 300 },
    { label: 'Account #', value: 'reference', type: 'string', filtering: true, minWidth: 80, maxWidth: 80 },
    { label: "Group #", value: "groupCode", type: 'string', filtering: true, minWidth: 80, maxWidth: 80 },   
    { label: 'Service Region', value: 'regionName', type: 'string', filtering: true, minWidth: 120, maxWidth: 120 },
    { label: 'Full Name', value: 'fullName', type: 'string', filtering: true, minWidth: 120, maxWidth: 120 },
    { label: 'Email Address', value: 'email', type: 'string', filtering: true, minWidth: 140, maxWidth: 140 },
    { label: 'Added At', value: 'dateAddedTxt', type: 'date', filtering: true, minWidth: 120, maxWidth: 120 },
    { label: 'Added By', value: 'createdByTxt', type: 'string', filtering: true, minWidth: 140, maxWidth: 140 },
    { label: 'Privilege', value: 'isAdminTxt', type: 'string', filtering: true, minWidth: 80, maxWidth: 80 },
    { label: 'Source', value: 'source', type: 'string', filtering: true, minWidth: 70, maxWidth: 70 },
    { label: 'Status', value: 'regStatusTxt', type: 'string', filtering: true, minWidth: 80, maxWidth: 80 }];


  const tablePageDefaults = { paging: { pages: [10, 20, 50, 100, 200], pageSelected: 50, limit: 50, offset: 0, orderBy: 'id', orderDir: 'DESC' } };
  const entityData = useRef([]);
  const welcomeEmailMapped = useRef([]);
  const requestCheckbox = useRef({});
  const [filter, setFilter] = useState({ button: 'Unactioned' });
  const defaultDialogue = { count: 0, show: false };
  const defaultBanner = { error: false, message: '' };
  const [showProcessDialogue, setShowProcessDialoge] = useState(defaultDialogue);
  const [showDeleteDialogue, setShowDeleteDialoge] = useState(defaultDialogue);
  const [bannerError, setBannerError] = useState(defaultBanner);
  const [localRender, setLocalRender] = useState(false);
  const [loading, setLoading] = useState(false);


  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';
  const exceptions = [400,404];
  

  const handleButtonsChanged = (buttonName, set) => {
    const newTableFilter = { ...filter };
    if (newTableFilter.button === buttonName) {
        newTableFilter.button = '';
    } else {
        newTableFilter.button = buttonName;
    }
    set(newTableFilter);
  };

  const tableDeleteRequest = () => {
    setBannerError(defaultBanner);
    const ids = Object.keys(requestCheckbox.current).filter(x => requestCheckbox.current[x]).map(x => Number(x));
    setShowDeleteDialoge({ ids: ids, count: ids.length, show: true });
  };

  const tableProcessRequst = () => {
    setBannerError(defaultBanner);
    const ids = Object.keys(requestCheckbox.current).filter(x => requestCheckbox.current[x]).map(x => Number(x));
    setShowProcessDialoge({ ids, count: ids.length, show: true });
  };

  const actionSelected = (row) => {
    requestCheckbox.current[row.id] = !requestCheckbox.current[row.id];
    setLocalRender(!localRender);
  };

  const actionDeleteRegistration = async () => {
    try {
      setLoading(true);
      const requestIdArray = showDeleteDialogue.ids.map(x => Number(x));
      const filtered = entityData.current.result.filter(x => requestIdArray.includes(x.id));
      const result = await Axios.delete('/entities/workflow/portals/actionDeleteUser/registration', { data: { entity: filtered } });
      if(exceptions.includes(result.status)) {
        if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
        if(result.status === 404) setBannerError({ error: true, message: notFoundError });
      } else {
        entityData.current.result = entityData.current.result.filter(x => !requestIdArray.includes(Number(x.id)));
        Object.keys(requestCheckbox.current).forEach(x => requestCheckbox.current[x] = false);
        setShowDeleteDialoge(defaultDialogue);
        setBannerError(defaultBanner);
        setLocalRender(!localRender);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      setBannerError({ error: true, message: exceptionError });
    }
    setLoading(false);
  };

  const actionSendEmail = async () => {
    try { 
      setLoading(true);
      const requestIdArray = showProcessDialogue.ids.map(x => Number(x));
      const filtered = entityData.current.result.filter(x => requestIdArray.includes(x.id));
      const respose = await Axios.put(`/entities/workflow/portals/actionSendWelcome`, filtered);
      if(exceptions.includes(respose.status)) {
        if(respose.status === 400) toast.error(invalidConfig);
        if(respose.status === 404) toast.error(notFoundError); 
      } else {
        entityData.current.result = entityData.current.result.filter(x => !requestIdArray.includes(Number(x.id)));
        Object.keys(requestCheckbox.current).forEach(x => requestCheckbox.current[x] = false);
        toast.info(`Welcome ${requestIdArray.length > 1 ? 'emails' : 'email'} successfully sent`);
        setShowProcessDialoge(defaultDialogue);
        setBannerError(defaultBanner);
        setLocalRender(!localRender);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      setBannerError({ error: true, message: exceptionError });
    }
    setLoading(false);
  };
  
  async function constructTable(tonderData) {

    const tableData = tonderData.map(row => {

      const allowSelect = (filter.button !== 'Unactioned');

      return {
        selected: { value: <UILIB.TableContainer data={<UILIB.Checkbox disabled={allowSelect} checked={requestCheckbox.current[row.id]} type='checkbox' onChange={() => actionSelected(row)}/>} /> },
        id: { value: row.id, raw: row.id, disabled: allowSelect },
        groupCode: { value: row.groupCode, raw: row.groupCode },
        customerName: { value: row.customerName, raw: row.customerName },
        reference: { value: row.reference, raw: row.reference },
        regionName: { value: row.regionName, raw: row.regionName },
        fullName: { value: row.fullName, raw: row.fullName },
        email: { value: row.email, raw: row.email },
        source: { value: getGridChip('createSource', row.source, ), raw: row.source },
        isAdminTxt: { value: getGridChip('admin', row.isAdmin), raw: row.isAdminTxt },
        dateAddedTxt: { value: formatDateTime(row.dateAdded), raw: formatDateTime(row.dateAdded) },
        createdByTxt: { value: row.createdByTxt, raw: row.createdByTxt },
        regStatusTxt: { value: getGridChip('regStatus', row.status, row.sentWelcomeEmailPending), raw: row.regStatusTxt }
      }
    })

    return tableData;
  }

  const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {

    const queryLocal = (query !== null) ? query : '';
    const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
    const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';
    const filterButtons = setFilterVarsRegistration(filter);

    entityData.current = await Axios.get(`/entities/portals_userbases/getSummary/?&$filter=${filterButtons} ${queryLocal} ${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(res => res.data);

    if (!entityData.current || !entityData.current.result.length || !Array.isArray(entityData.current.result)) return false;

    let checked = {};
    if (entityData.current && entityData.current.result) {
      entityData.current.result.forEach(x => { return checked = { ...checked, [x.id]: false } })
      requestCheckbox.current = checked;
    }

    let welcomeEmail = {};
    if(entityData.current && entityData.current.result) {
      entityData.current.result.forEach(x =>  { return welcomeEmail = { ...welcomeEmail, [x.id]: false } });
      welcomeEmailMapped.current = welcomeEmail;
    }

    return { tableData: await constructTable(entityData.current.result), raw: entityData.current.result, nonePaged: entityData.current.nonePaged };
  }


  return <Fragment>
    <ToastContainer
      progressClassName="toastify"
      position="bottom-right"
      autoClose={10000}
      style={{ width: 500 }}
      hideProgressBar={false}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover />
    {showProcessDialogue.show && <UILIB.MessageBox
      header={'Process Requests'}
      text={`Click OK to PROCESS the selected ${(showProcessDialogue.count === 1) ? 'Record' : 'Records' }`}
      loading={loading}
      errored={bannerError}
      onConfirm={async () => await actionSendEmail()}
      onCancel={() => setShowProcessDialoge({ ...showDeleteDialogue, show: false })} />}
    {showDeleteDialogue.show && <UILIB.MessageBox
      header={'Delete Requests'}
      text={`Click OK to DELETE the selected ${(showDeleteDialogue.count === 1) ? 'Record' : 'Records' }`}
      subText={`NOTE! Deleting a user will also delete the Portal User from VO`}
      loading={loading}
      errored={bannerError}
      onConfirm={async () => await actionDeleteRegistration()}
      onCancel={() => setShowDeleteDialoge({ ...showDeleteDialogue, show: false })} />}
    <UILIB.Paper className='width-100'>
      <div className='flex-container row mar-b10'>
        <div className='flex-item flex-grow-1 start wrap'>
          <UILIB.Button className={'mar-r5 small ' + (!filter.button || filter.button === 'Unactioned' ? 'primary' : 'secondary')} name='Unactioned' value='Unactioned' onClick={(ev) => handleButtonsChanged(ev.target.name, setFilter)} />
          <UILIB.Button className={'mar-l5 mar-r5 small ' + (!filter.button || filter.button === 'Pending' ? 'primary' : 'secondary')} name='Pending' value='Pending' onClick={(ev) => handleButtonsChanged(ev.target.name, setFilter)} />
          <UILIB.Button className={'mar-l5 mar-r5 small ' + (!filter.button || filter.button === 'Actioned' ? 'primary' : 'secondary')} name='Actioned' value='Actioned' onClick={(ev) => handleButtonsChanged(ev.target.name, setFilter)} />
          <UILIB.Button className={'mar-l5 mar-r5 small ' + (!filter.button || filter.button === 'Deleted' ? 'primary' : 'secondary')} name='Deleted' value='Deleted' onClick={(ev) => handleButtonsChanged(ev.target.name, setFilter)} />
        </div>
        <div className='flex-item end flex-grow-1'>
          <UILIB.Button 
            value="Delete" 
            className="button red" 
            disabled={(!Object.values(requestCheckbox.current).some(x => x) || (filter.button !== 'Unactioned' && filter.button !== 'Pending'))} 
            onClick={() => tableDeleteRequest()} />
          <UILIB.Button 
            value="Process" 
            className="button secondary" 
            disabled={(!Object.values(requestCheckbox.current).some(x => x) || (filter.button !== 'Unactioned'))} 
            onClick={() => tableProcessRequst()} />
        </div>
      </div>
      <div className='width-100'>
        <UILIB.TableNew
          name='PortalRequestLookup'
          className='small'
          overflowX='auto'
          overflowY='hidden'
          header={headerData}
          deleteQuery={(ev) => tableDeleteRequest(ev)}
          localQuery={() => constructTable((entityData.current.result && entityData.current.result.length) ? entityData.current.result : [])}
          localRender={[localRender]}
          remoteQuery={getTableData}
          remoteRender={[filter]}
          defaultSettings={tablePageDefaults} />
      </div>
    </UILIB.Paper>
  </Fragment>
}
