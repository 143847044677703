import { useState, useRef, useEffect, memo, Fragment } from 'react';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Session from "classes/session";
import UILIB from 'components';
import axios from 'axios';
import Logo from 'assetts/logos/agilicoLogo.png';
import BgImage1 from 'assetts/backgrounds/snowymountain.jpg';
import BgImage2 from 'assetts/backgrounds/jungle1.jpg';
import BgImage3 from 'assetts/backgrounds/meadows.jpg';
import { setRefreshToken, setAccessToken, setMomentSession } from 'store/actions';
import moment from 'moment-timezone';


const LoginPage = memo(() => 
{

    const dispatch = useDispatch();
    const history = useHistory();
    const loginRightStlye = useRef(undefined);
    const [showLogin, setShowLogin] = useState(true);
    const [count, setCount] = useState(0);

    const imageArray = [BgImage1, BgImage2, BgImage3];
    const imageData = imageArray[count % imageArray.length];
    const defaultStyle = { height: "100%", backgroundImage: "url(" + BgImage3 + ")", backgroundSize: "cover" };

    const params = new URLSearchParams(window.location.search);
    const logoStyle = { width: "150px", marginLeft: "-7px" };


    useEffect(() => {
      const asyncEffect = async () => {
        await Session.getSessionTimer(10000);
        count === 2 ? setCount(0) : setCount(count => count + 1);
        loginRightStlye.current.style.backgroundImage = "url(" + imageData + ")";
      }
      asyncEffect();
    }, [count]);  
    
    useEffect(() => {
      const asyncEffect = async () => {
        try {
          if (params.get("tk")) {

            const result = await axios.post(`${process.env.REACT_APP_ENV_URL}/getAccessToken`, { refreshToken: params.get("tk") });

            setShowLogin(false);

            history.replace('/');

            dispatch(setRefreshToken(params.get("tk"))); 
            dispatch(setAccessToken(result.data.accessToken)); 
            dispatch(setMomentSession(moment()));

          } else {
            history.push('/login');
            setShowLogin(
              <Fragment>
                <div className="loginOuter row">
                  <div className="loginLeft col-xs-12 col-sm-6 col-md-5 col-lg-4">
                    <div>
                      <img src={Logo} style={logoStyle} className="mar-b15" alt="Agilico" />
                      <h1 className="text-white">Welcome to Agilico Business Hubs</h1>
                      <p className="mar-b15 text-white">
                        Please click below to login, using your Agilico Microsoft Office Credentials. 
                        Your username will  be your usual login username followed by @dmcplc.co.uk.
                      </p>
                      <UILIB.Button value="Login" className="mar-b15 secondary" onClick={() => submitForm()} />
                    </div>
                  </div>
                  <div style={defaultStyle} ref={loginRightStlye} className="loginRight hidden-xs col-sm-6 col-md-7 col-lg-8"></div>
                </div >
              </Fragment>
            )
          }
        }
        catch (err) {
          console.log(err);
          history.push('/login');
          window.location.reload(false);
        }
      }
      asyncEffect();
    }, []);   

    const submitForm = () => {

      let theUrl = "https://login.microsoftonline.com/6d06fd86-08ea-4967-94b4-a589b9654769/oauth2/authorize?";
      theUrl += "client_id=d0508098-38a3-4979-9272-fefbb050d32d";
      theUrl += "&response_type=id_token";
      theUrl += "&redirect_uri=" + encodeURI(process.env.REACT_APP_ENV_URL + `/login/2fa?mode=${process.env.REACT_APP_ENV_MODE}`);
      theUrl += "&response_mode=form_post";
      theUrl += "&scope=openid";
      theUrl += "&state=superstring";
      theUrl += "&nonce=7362CAEA-9CA5-4B43-9BA3-34D7C303EBA7";
      theUrl += "&prompt=login";
      window.open(theUrl, '_self');

    }

    return showLogin;

})
export default LoginPage
