import { useState, Fragment } from 'react';
import Axios from 'classes/axios';
import moment from "moment-business-time";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getToastMessage } from '../functions';
import { createButton, createSelectInput, yesNo } from 'classes/controls/formControls';


export default function EditUserDrawer({ entity, sync }) {

  const [formData, setFormData] = useState({ ...entity });
  const [emailSaving, setEmailSaving] = useState(false);
  const [passwordSaving, setPasswordSaving] = useState(false);
  const [syncing, setSyncing] = useState({ syncIsActive: false });
  const exceptions = [204,400,404,409];

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';
  const successMessage = 'User record updated';


  const actionUpdateEntity = (event) => {
    setFormData({  ...formData, [event.target.name]: event.target.value });
  };

  const actionSyncData = async (event, value, field) => {
    try {
      setSyncing({ ...syncing, [event.target.name]: true });
      const result = await Axios.patch(`/entities/workflow/portals/actionUserActiveStatus/`, formData); 
      if(exceptions.includes(result.status)) {
        if(result.status === 400) toast.error(invalidConfig);
        if(result.status === 404) toast.error(notFoundError);
      } else {
        toast.info(successMessage);
        entity[field] = value;
        sync.set(!sync.get);
      }
    } catch (error) {
      console.log(error);
      toast.error(exceptionError);
    }
    setSyncing({ ...syncing, [event.target.name]: false });
  };

  const handleSendWelcome = async (row) => {
    try { 
      setEmailSaving(true);
      const respose = await Axios.put(`/entities/workflow/portals/actionResendWelcome/${row.id}`);
      if(exceptions.includes(respose.status)) {
        if(respose.status === 400) toast.error(invalidConfig);
        if(respose.status === 404) toast.error(notFoundError); 
      } else {
        toast.info(getToastMessage(row, 1));
      }
      setEmailSaving(false);
    } catch (err) {
      console.log(err);
      setEmailSaving(false);
      toast.error(exceptionError);
    }
  };

  const handleSendPassword = async (row) => {
    try { 
      setPasswordSaving(true);
      const respose = await Axios.post(`/entities/workflow/portals/actionResetPasswordEmail/${row.id}`);
      if(exceptions.includes(respose.status)) {
        if(respose.status === 400) toast.error(invalidConfig);
        if(respose.status === 404) toast.error(notFoundError); 
      } else {
        toast.info(getToastMessage(row, 2));
      }
      setPasswordSaving(false);
    } catch (err) {
      console.log(err);
      setPasswordSaving(false);
      toast.error(exceptionError);
    }
  };


  return <Fragment>
    <ToastContainer
      progressClassName="toastify"
      position="bottom-right"
      autoClose={10000}
      style={{ width: 500 }}
      hideProgressBar={false}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover />
    <div className="flex-container row width-100">
      <div className="flex-item center align-center font-weight-bold text-16 mar-t20 mar-b10 width-100">User Options</div>
      <div className="flex-container row start width-100">
        {createSelectInput('Is Active', 'isActive', (Boolean(entity.status === 2) || syncing.syncIsActive), formData.isActive, yesNo, (ev) => actionUpdateEntity(ev), null, 'flex-grow-1', null, null, 'fullBorder border-colour-grey-4')}
        {createButton('', 'syncIsActive', 'Apply', (Boolean(entity.status === 2) || syncing.syncIsActive), syncing.syncIsActive, async (ev) => await actionSyncData(ev, Number(formData.isActive), 'isActive'), null, 'flex-item end min-width-150', null, null, null, 'Attempt to Sync with VO')}
      </div>
      <div className="flex-container row width-100">
        <p className="flex-item start flex-grow-1 center text-13 pad-l5">Re-Send Welcome Email</p>
        {createButton('', 'sendWelcome', 'Send', Boolean(entity.status === 2), emailSaving, async () => await handleSendWelcome(entity), null, 'flex-item end min-width-150')}
      </div>
      <div className="flex-container row width-100">
        <p className="flex-item start flex-grow-1 center text-13 pad-l5">Send Password Reminder</p>
        {createButton('', 'sendPassword', 'Send', Boolean(entity.status === 2), passwordSaving, async () => await handleSendPassword(entity), null, 'flex-item end min-width-150')}
      </div>
      <div className="flex-item center align-center font-weight-bold text-16 mar-t20 mar-b10 width-100">User Information</div>
      <div className="flex-container row width-100">
        <p className="flex-item start center text-13 pad-l5 width-40">Created Date:</p>
        <i className="flex-item start text-12 pad-l5">{(entity.createdAt) ? moment(entity.createdAt).format('DD/MM/YYYY') : 'Unknown'}</i>
      </div>
      <div className="flex-container row width-100">
        <p className="flex-item start center text-13 pad-l5 width-40">Updated At:</p>
        <i className="flex-item start text-12 pad-l5">{(entity.updatedAt) ? moment(entity.updatedAt).format('DD/MM/YYYY') : 'Unknown'}</i>
      </div>
      <div className="flex-container row width-100">
        <p className="flex-item start center text-13 pad-l5 width-40">Updated By:</p>
        <i className="flex-item start text-12 pad-l5">{(entity.updatedByTxt) ? entity.updatedByTxt : 'Unknown'}</i>
      </div>
      <div className="flex-container row width-100">
        <p className="flex-item start center text-13 pad-l5 width-40">Updated At (VO):</p>
        <i className="flex-item start text-12 pad-l5">{(entity.modifiedDate) ? moment(entity.modifiedDate).format('DD/MM/YYYY') : 'Unknown'}</i>
      </div>
      <div className="flex-container row width-100">
        <p className="flex-item start center text-13 pad-l5 width-40">Updated By (VO):</p>
        <i className="flex-item start text-12 pad-l5">{(entity.modifiedBy) ? entity.modifiedBy : 'Unknown'}</i>
      </div>
    </div>
  </Fragment>

}
