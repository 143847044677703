import { useState, useRef, Fragment } from "react";
import { useDispatch } from "react-redux";
import { formatDateTime } from "classes/format";
import Axios from "classes/axios";
import UILIB from "components";
import { getYesNo, actionOpenNotesDrawer, tableContainer, setFilterVars } from '../functions'

export default function MIFDrilldownTable({ context, filter }) {
  const headerData = [
    { label: "Notes", value: "notes", type: "string", filtering: false, minWidth: 60, maxWidth: 60, filterArray: getYesNo() },
    { label: "Service Region", value: "serviceRegion", type: "string", filtering: true, minWidth: 150, maxWidth: 150 },
    { label: "Account #", value: "accountNo", type: "string", filtering: true, minWidth: 120, maxWidth: 120 },
    { label: "Customer Name", value: "custName", type: "string", filtering: true, minWidth: 250, maxWidth: 250 },
    { label: "Device Serial", value: "serialNumber", type: "string", filtering: true, minWidth: 130, maxWidth: 130 },
    { label: "Primary Comms Method", value: "primaryComms", type: "string", filtering: true, minWidth: 140, maxWidth: 140 },
    { label: "Last Comms Date", value: "primaryCommsDate", type: "date", filtering: true, minWidth: 120, maxWidth: 120 },
    { label: "Device Model", value: "description", type: "string", filtering: true, minWidth: 270, maxWidth: 270 },
    { label: "Location", value: "location", type: "string", filtering: true, minWidth: 180, maxWidth: 180 },
    { label: "Postcode", value: "postCode", type: "string", filtering: true, minWidth: 100, maxWidth: 100 },
    { label: "Installation Date", value: "installDate", type: "date", filtering: true, minWidth: 130, maxWidth: 130 },
    { label: "Secondary Comms Method", value: "secondaryComms", type: "string", filtering: true, minWidth: 160, maxWidth: 160 },
    { label: "Last Comms Date", value: "secondaryCommsDate", type: "date", filtering: true, minWidth: 120, maxWidth: 120 },
    { label: "Last Comms Date Any", value: "lastCommsDateAny", type: "date", filtering: true, minWidth: 130, maxWidth: 130 },
    { label: "Device Monitored", value: "monitored", type: "string", filtering: true, minWidth: 120, maxWidth: 120 },
    { label: "Customer Grade", value: "customerGrade", type: "string", filtering: true, minWidth: 100, maxWidth: 100 },
    { label: "Collection Method", value: "collectionMethod", type: "string", filtering: true, minWidth: 100, maxWidth: 100 },
  ];

  const dispatch = useDispatch();
  const pageContext = context.context;
  const entityData = useRef({});
  const pageSelectedNumber = 50;
  const tablePageDefaults = { deleteEnabled: true, paging: { pages: [10, 20, 50, 100, 200], pageSelected: pageSelectedNumber, limit: pageSelectedNumber, offset: 0, orderBy: 'custName', orderDir: 'ASC' } };
  const [remoteRender, setRemoteRender] = useState(false);

  async function constructTable(mifData) {

    const tableData = mifData.map(row => {

      const b_hasNotes = Boolean(row.notes && row.notes.length);
      const fn_notes = actionOpenNotesDrawer.bind(null, 'ViewNotesDrawer', row, dispatch, true, { get: remoteRender, set: setRemoteRender }, false);
      const orderedNotes = b_hasNotes ? row.notes
        .map(note => ({ ...note, createdAt: new Date(note.createdAt) }))
        .sort((a, b) => b.createdAt - a.createdAt)
        : row.notes;
      let notesColour;
      let notesValue;
      
      if(!b_hasNotes)  {
        notesColour = 'colour lightGrey';
        notesValue = 'null';
      }
      else if(b_hasNotes && orderedNotes[0].NoteSource === 'Exception') {
        notesColour = 'colour background-6 red';
        notesValue = 'Exception';
      }
      else {
        notesColour = 'colour background-6 green';
        notesValue = 'Note';
      }

      return {
        notes: { value: tableContainer(fn_notes, 'icon-pencil', `View Notes - ${notesValue}`, notesColour, true), raw: notesValue },
        serviceRegion: { value: row.serviceRegion, raw: row.serviceRegion },
        accountNo: { value: row.accountNo, raw: row.accountNo },
        custName: { value: row.custName, raw: row.custName },
        serialNumber: { value: row.serialNumber, raw: row.serialNumber },
        description: { value: row.description, raw: row.description },
        location: { value: row.location, raw: row.location },
        postCode: { value: row.postCode, raw: row.postCode },
        installDate: { value: formatDateTime(row.installDate, "DD/MM/YYYY"), raw: row.installDate },
        primaryComms: { value: row.primaryComms, raw: row.primaryComms },                                                                                                         
        primaryCommsDate: { value: formatDateTime(row.primaryCommsDate, "DD/MM/YYYY"), raw: row.primaryCommsDate },
        secondaryComms: { value: row.secondaryComms, raw: row.secondaryComms },
        secondaryCommsDate: { value: formatDateTime(row.secondaryCommsDate, "DD/MM/YYYY"), raw: row.secondaryCommsDate },
        lastCommsDateAny: { value: formatDateTime(row.lastCommsDateAny, "DD/MM/YYYY"), raw: row.lastCommsDateAny },
        monitored: { value: row.monitored, raw: row.monitored },
        customerGrade: { value: row.customerGrade, raw: row.customerGrade },
        collectionMethod: { value: row.collectionMethod, raw: row.collectionMethod },
      };
    });

    return tableData;
  }

  const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken, source) => {
    try {
      const expandArray = (source === 'primary' || source === 'export') ? ['notes'] : [];
      const queryLocal = (query !== null) ? query : '';
      const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
      const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';
      const filterButtons = setFilterVars(pageContext, filter);
      const buttonAppend = filterButtons ? ` ${filterButtons} ` : '';

      entityData.current = await Axios.get(`/entities/mif/getSummaryDrilldown/${pageContext}/?&$filter=${buttonAppend}${queryLocal}&$expand=${expandArray.map(x => x).join(' and ')}${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(api => api.data);

      if(!entityData.current || !entityData.current.result.length) return false;

      return { tableData: await constructTable(entityData.current.result), nonePaged: entityData.current.nonePaged };
    }
    catch(err) {
      console.log(err);
    }
  }

  return (
    <Fragment>
      <UILIB.Paper className="width-100">
        <UILIB.TableNew
          name={`RMB${pageContext}`}
          className="small"
          overflowX="auto"
          overflowY="hidden"
          header={headerData}
          localQuery={() => constructTable(entityData.current && entityData.current.result ? entityData.current.result : [])}
          remoteQuery={getTableData}
          remoteRender={[filter, remoteRender]}
          defaultSettings={tablePageDefaults} />
      </UILIB.Paper>
    </Fragment>
  );
}
