import CustomerLookup from './modules/customerLookup';
import GuardianReport from './modules/guardianReport';
import RequestPortalDrawer from './modules/requestPortalDrawer';
import RequestsLookup from './modules/requestsLookup';
import EditRequestDrawer from './modules/requestDrawer';
import RegistrationLookup from './modules/registrationLookup';
import UserLookup from './modules/userLookup';
import ReportDrawer from './modules/reportDrawer';
import EditUserDrawer from './modules/usersDrawer';
import CustomerDrawerEvent from './modules/customerDrawerEvent';
import ViewNotesDrawer from './modules/viewNotesDrawer';
import PortalUtilities from './modules/portalUtilities';


const exported = {
  CustomerLookup,
  GuardianReport,
  RequestPortalDrawer,
  RequestsLookup,
  RegistrationLookup,
  EditRequestDrawer,
  UserLookup,
  ReportDrawer,
  EditUserDrawer,
  CustomerDrawerEvent,
  ViewNotesDrawer,
  PortalUtilities
}
export default exported;