import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UILIB from 'components';
import Axios from 'classes/axios';
import { checkFormErrors } from 'classes/formErrors/formErrors';
import { getLogisticsUsers } from "classes/helpers";
import BlockDevicePage from '../../cp/logisticsHub/deviceMovement/tabs/blockDevicePage';
import BlockQuotePage from '../../cp/logisticsHub/deviceMovement/tabs/blockQuotePage';
import BlockAuthorizationPage from './tabs/blockAuthorizationPage';


export default function DeviceCollect() {

  const { formid } = useParams()
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [logisticsUsers, setLogisticsUsers] = useState([])
  const [currentTab, setCurrentTab] = useState(0);
  const [submitted, setSubmitted] = useState(false)
  const [formData, setFormData] = useState([])

  const [formErrors, setFormErrors] = useState({
    signedName: { value: "", disabled: false, error: false },
    signedDate: { value: "", disabled: false, error: false }
  });


  const submitForm = async (formid, formData, hist) => {

    setSubmitted(true); 

    const formResult = await checkFormErrors(...formErrors, setFormErrors, null, null, formData, null);
 
    if (!formResult.errorCount) {
        const hostData = {
          hostname: window.location.host,
          protocol: window.location.protocol
        }
        Axios.post(`/logistics/deviceMovement/customerSigned?id=${formid}`, [formData, hostData]).then(() => {
          hist.push('/public/customerSignComplete')
        })
        setSubmitted(false); 
    }
    else {
        if(formResult.errorCount > 0) toast.error(formResult.errorCount + "/" + formResult.totalCount + " fields require attention before saving");
        setSubmitted(false); 
    }
  }


  useEffect(() => {  
    (async () => {

        setLoading(true);

        const users = await getLogisticsUsers()
        const logisticsUsersNew = JSON.parse(JSON.stringify(users))
        logisticsUsersNew.unshift({ label: "Please Select", value: "" })
        setLogisticsUsers(logisticsUsersNew)

        let formData = await Axios.get(`/logistics/deviceMovement/getFormData?rowId=${formid}`)

        if(formData.data.data === null || formData.data.data.allDone === 1) history.push('/public/customerSignError')

        let formDataNew = JSON.parse(JSON.stringify(formData.data.data));
        setFormData(formDataNew);
        setLoading(false);  

    })()
  }, [formid, history])



  const tabs = [
    { label: "Details & Authorization", value: 0 }, 
    { label: "Devices", value: 1 }, 
    { label: "Quote", value: 2 } 
  ]



  if (loading) {
    return (
      <div style={{position: 'absolute', left: '50%', top: '20%',transform: 'translate(-50%, -50%)'}}>
        <div className="col center"><h2>Loading, Please wait</h2>
          <UILIB.Loading type={3} />
        </div>
      </div>
    )
  }


  return (
    <div className="row">
        <ToastContainer
          progressClassName="toastify"
          position="bottom-right"
          autoClose={5000}
          style={{ width: 500 }}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="col-xs-12 mar-t20 mar-b20 center">
            <a href="https://www.dmcplc.co.uk/" target = "_blank" rel="noopener noreferrer">
                <img src="https://www.agilico.co.uk/wp-content/uploads/2021/10/VO-Header-Agilico.png" alt="Italian Trulli"></img>
            </a>
        </div>
        <div className="col-xs-12">
          <UILIB.SubNav tabs={tabs} currentTab={currentTab} outerClasses="mar-t15 mar-b15" onClick={(newId) => setCurrentTab(newId)} />
        </div>
        {currentTab === 0 &&
        <div className="col-xs-12 col-sm-12 col-md-12">
          <BlockAuthorizationPage
            formData={formData}
            setFormData={setFormData}
            loading={loading}
            logisticsUsers={logisticsUsers}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
            submitForm={submitForm}
            submitted={submitted}
          />
        </div>}
        {currentTab === 1 &&
        <div className="col-xs-12 col-sm-12 col-md-12">
          <BlockDevicePage
            formData={formData}
            setFormData={setFormData}
            loading={loading}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
          />
        </div>}
        {currentTab === 2 &&
        <div className="col-xs-12 col-sm-12 col-md-12">
          <BlockQuotePage
            formData={formData}
            setFormData={setFormData}
            loading={loading}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
          />
        </div>}
    </div>
  );
}


