import { useState, useReducer, useEffect, useRef, memo } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setDrawer } from "store/actions";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import { useParams } from 'react-router';
import { store } from 'store/store'
import UILIB from 'components';
import Axios from 'classes/axios'
import * as errorFunctions from '../../../../../classes/formErrors/formErrors';
import * as errorRepository from '../../../../../classes/formErrors/repository';
import OrderDetailPage from './detailPage/orderDetails';
import Modules from '../Modules';
import AdditionalInfoPage from '../shared/additionalInfoPage/additionalInfoPage';
import DocumentsPage from '../shared/documents/documentsPage';
import InstallChecklistTable from '../../../logisticsHub/tables/installChecklistTable';
import { permissionsObject } from 'classes/permissions';
import { DATA_TO_LOAD, reducer, reduceArray } from '../shared/reducer';
import * as functions from '../shared/functions';
import * as sideLoad from '../shared/sideLoad';


const BlockOrders = memo(() => 
{
  const dispatch = useDispatch();
  const state = store.getState();
  const account = state.account;
  const history = useHistory({ forceRefresh: true });
  const params = useParams();
  const location = useLocation();
  const route = location.pathname;
  const userAccess = useRef([]);
  const permissions = useSelector((state) => state.permissions);
  const productTickedEvent = useRef({ selectedProducts: [], productCount: 0 });
  const localPermissions = useRef({});
  const [isActive, setisActive] = useState(false);
  const [localEvent, setLocalEvent] = useState({ timeout: 500 });
  const [dealSheetLoading, setDealSheetLoading] = useState(false);
  const [submitModal, setSubmitModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [dealLoaded, setDealLoaded] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [refreshData, setRefreshData] = useState(false);
  const [dealAddress, setDealAddress] = useState([]);
  const [tabErrors, setTabErrors] = useState([]);
  const [productErrors, setProductErrors] = useState([]);
  const [invoiceErrors, setInvoiceErrors] = useState(errorRepository.getBlockOrderInvoiceErrors());
  const [formErrors, setFormErrors] = useState(errorRepository.getBlockOrderFormErrors());
  const [additionalInfoErrors, setAdditionalInfoErrors] = useState(errorRepository.getBlockOrderAdditionalInfoErrors());
  const [documentErrors, setDocumentErrors] = useState(errorRepository.getBlockOrderDocumentErrors());
  const [render, setRender] = useState(Math.random());
  const processData = useRef('');
  const [activeFields, setActiveFields] = useState([]);
  const [dealData, setDealData] = useState(undefined);
  const [dataLoaded, setDataLoaded] = useState(reduceArray(DATA_TO_LOAD)); 
  const [dataToLoad, reducerDispatch] = useReducer(reducer, { dataLoaded: [] }); 
  const [productConfigurationModal, setProductConfigurationModal] = useState({
    open: false,
    type: null,
    product: null,
    dealProductId: null,
    portalProductId: null
  });
  const [fieldWorkflowObj, setFieldWorkflowObj] = useState({
    leaseCompanyInfo: false,
    leaseRate: false,
    rvIncluded: false,
    rvPercent: false,
    companyPaymentTerms: false,
    companyPaymentLength: false,
    leasePeriodPayment: false,
    dealCurrency: false,
    leasePeriod: false,
    rebateValue: false,
    rebatePercent: false,
    isMember: false,
    cpcLocation: false,
    cpcLot: false,
    serviceTerm: false,
    serviceContract: false,
    hidePanelRight: false,
    organisation: false,
    sector: false,
    procurementLevel: false,
    miniCompOrDirectBuy: false,
    scottishProcurementLease: false
  });


  const checkErrors = async (submitting) => {

    const tabErrorsLocal = [];
    const productErrorsNew = [];

    dealData.product.filter(p => p.parentId === 0).forEach(row => {
      const disabledDecider = (((row.portalPart && row.portalPart.SalesPartType && row.portalPart.SalesPartType.name === "MFP - Equipment") || (row.rowType === 1 && row.isADevice === 1)) && dealData.leaseorcash !== 'Cash - Trade (SOP Only)') ? false : true;
      productErrorsNew.push({ name: "siteAddress", rowId: row.prodID, value: row.siteAddress, option: 0, subNav: 0, disabled: false, error: false })
      productErrorsNew.push({ name: "specificDeviceLocation", rowId: row.prodID, value: row.specificDeviceLocation, option: 0, subNav: 0, disabled: disabledDecider, error: false })
      productErrorsNew.push({ name: "cpcMono", rowId: row.prodID, value: row.cpcMono, option: 0, subNav: 0, disabled: disabledDecider, error: false })
      productErrorsNew.push({ name: "cpcColour", rowId: row.prodID, value: row.cpcColour, option: 0, subNav: 0, disabled: disabledDecider, error: false })
      fieldWorkflowObj.scottishProcurementLease && productErrorsNew.push({ name: "lease", rowId: row.prodID, value: row.lease, option: 0, subNav: 0, disabled: disabledDecider, error: false })
    })

    const crmAddress = (dealData.invoiceAddress.length > 0) ? true : null;

    const formResult = await errorFunctions.checkFormErrors(formErrors, setFormErrors, tabErrorsLocal, setTabErrors, dealData, null);
    const invoiceResult = await errorFunctions.checkFormErrors(invoiceErrors, setInvoiceErrors, tabErrorsLocal, setTabErrors, { crmAddress: crmAddress }, null);
    const productResult = await errorFunctions.checkProductErrors(productErrorsNew, setProductErrors, tabErrorsLocal, setTabErrors, dealData.product, null);
    const additionalResult = await errorFunctions.checkAdditionalInfoErrors(additionalInfoErrors, setAdditionalInfoErrors, tabErrorsLocal, setTabErrors, dealData, null);
    const documentResult = await errorFunctions.checkDocumentErrors(documentErrors, setDocumentErrors, tabErrorsLocal, setTabErrors, dealData.document, null);

    if (formResult.errorCount === 0 && invoiceResult.errorCount === 0 && productResult.errorCount === 0 && additionalResult.errorCount === 0 && documentResult.errorCount === 0) {
      if(submitting) setSubmitModal(true);
      return true;
    }
    else {
      setRefreshData(refreshData ? false : true);
      if (formResult.errorCount > 0) toast.error(formResult.errorCount + "/" + formResult.totalCount + " Form fields require attention before saving");
      if (invoiceResult.errorCount > 0) toast.error("Please add an Invoice Address before saving");
      if (additionalResult.errorCount > 0) toast.error(additionalResult.errorCount + "/" + additionalResult.totalCount + " Contacts require attention before saving");
      if (productResult.errorCount > 0) toast.error(productResult.errorCount + "/" + productResult.totalCount + " Product fields require attention before saving");
      if (documentResult.errorCount > 0) toast.error(documentResult.errorCount + "/" + documentResult.totalCount + " Documents require attention before saving");
      return false;
    }
  }


  const loadOrder = async (dealID) => {

    try {

      setDealLoaded(false);

      let finalDealId = undefined;
      if (dealID) {
        finalDealId = dealID;
      } else if (dealLoaded && dealData && dealData.dealID) {
        finalDealId = dealData.dealID;
      } else {
        throw new Error('error loading deal, no deal id');
      }

      //load deal data
      const baseData = await Axios.get(`/entities/workflow/deals/actionGetDealData/${finalDealId}`); 

      //redirect if not an order
      if (baseData.data.result.processStage < 1) history.push(route.replace('/order/', '/quote/'));

      //check group specific access
      userAccess.current = await functions.checkAccess(account, baseData.data.result);

      const calculateFields = {};
      Object.keys(baseData.data.result).forEach(k => calculateFields[`${k}-${k}`] = false);
      setActiveFields(calculateFields);

      if (baseData.data.result.processStage < 2) {
        setisActive(baseData.data.result.isActive && Object.values(userAccess.current.read).indexOf(false) > -1);
      }
      if (baseData.data.result.processStage === 2) {
        setisActive(baseData.data.result.isActive && userAccess.current.users.isAdmin && (Object.values(userAccess.current.read).indexOf(false) > -1));
      }
      if (baseData.data.result.processStage > 2) {
        setisActive(false);
      }
      if (Object.values(userAccess.current.users).indexOf(true) === -1) {
        history.push({
          pathname: '/salesHub/accountmanagerview/draftview',
          state: {
            message: {
              showPermissionMessage: true
            }
          }
        })
      }

      processData.current = functions.getQuoteProcessData(baseData.data.result);

      const documentErrorsNew = {...documentErrors};
      documentErrorsNew.documents.fields = functions.getDoctype(baseData.data.result.leaseorcash, Number(baseData.data.result.serviceContract));
      setDocumentErrors(documentErrorsNew);

      setDealData(baseData.data.result);
      setDealLoaded(true);
    }
    catch (error) {
      console.log(error);
      if (history && history.location && history.location.key) {
        history.goBack();
      }
      else {
        history.push('/');
      }
    }
  }

  const sideLoadData = (dealID, oppNumber, accountNo) => {
    sideLoad.getProducts(reducerDispatch, dealID);
    sideLoad.getCrm(reducerDispatch, oppNumber);
    sideLoad.getDocuments(reducerDispatch, oppNumber);
    sideLoad.getCompatibles(reducerDispatch);
    sideLoad.getPSUsers(reducerDispatch);
    sideLoad.getDeviceNumbers(reducerDispatch, dealID);
    sideLoad.getHostConfig(reducerDispatch);
    sideLoad.getOtherDeals(reducerDispatch, oppNumber);
    sideLoad.getRemovals(reducerDispatch, dealID);
    sideLoad.getRemovalStores(reducerDispatch, accountNo);
    sideLoad.updateDealData(reducerDispatch, dealID, oppNumber);
    sideLoad.getProcurmentData(reducerDispatch);
    sideLoad.getDealPricing(reducerDispatch, dealID);
    sideLoad.getKeyContacts(reducerDispatch, dealID);
    sideLoad.getNotesData(reducerDispatch, dealID);
    sideLoad.getSettlementData(reducerDispatch, dealID);
    sideLoad.getAddressData(reducerDispatch, dealID);
    sideLoad.getInvoiceAddressData(reducerDispatch, dealID);
  }

  useEffect(() => {
    if(!dealLoaded) return;
    sideLoadData(dealData.dealID, dealData.dynamicsOpportunityNumber, dealData.accountNo);
  }, [dealLoaded])

  useEffect(() => {
    if(!dealLoaded) return;
    setDealData({ 
      ...dealData, 
      ...dataToLoad,
      addressDropdown: functions.getAddressDropdown({ ...dealData, ...dataToLoad })
    });
  }, [dataToLoad])

  useEffect(() => {
    if(!dealLoaded) return;
    setDataLoaded({ ...dataLoaded, ...dataToLoad.dataLoaded });
  }, [dataToLoad])
  
  useEffect(() => {
    functions.fieldWorkflow(dealData, {
      fieldWorkflowObj: fieldWorkflowObj,
      setFieldWorkflowObj: setFieldWorkflowObj,
      formErrors: formErrors,
      setFormErrors: setFormErrors,
      additionalInfoErrors: additionalInfoErrors,
      setAdditionalInfoErrors: setAdditionalInfoErrors
    });
  }, [dealData])

  useEffect(() => {
    const asyncEffect = async () => {
      if (params && params.dealID) {
        await loadOrder(params.dealID);
      }
      else {
        history.push("/");
      }
    }
    asyncEffect();
  }, [])

  useEffect(() => {
    try {
      let activeFieldsLocal = {};
      
      Object.keys(activeFields).forEach(x => (x === `${localEvent?.event?.target?.name}-${localEvent?.event?.target?.name}`) 
        ? activeFieldsLocal[x] = false 
        : activeFieldsLocal[x] = true); 
      setActiveFields(activeFieldsLocal);
  
      const timer = setTimeout(async () => {

        const element = document.getElementsByName(`${localEvent?.event?.target?.name}`);
        if(element.length) element[0].disabled = true;

        if (Number(localEvent.type) === 0) await functions.updateMainFormEntity(dealData, setDealData, localEvent.event);
        if (Number(localEvent.type) === 2) {
          const documentErrorsNew = structuredClone(documentErrors);
          documentErrorsNew.fields = functions.getDoctype(dealData.leaseorcash, Number(dealData.serviceContract));
          setDocumentErrors(documentErrorsNew);
          await functions.updateMainFormEntity(dealData, setDealData, localEvent.event);
        }
        if (Number(localEvent.type) === 3) {
          const mapped = dealData.product.map(row => { 
            return { 
              prodID: row.prodID, 
              dealID: row.dealID, 
              rowVersion: row.rowVersion, 
              cpcMono: row.cpcMono, 
              cpcColour: row.cpcColour,
              supression: true  
            }
          });

          await functions.updateMainFormEntity(dealData, setDealData, localEvent.event);
          await functions.updateProducts(dealData.dealID, mapped);
        }
        if(Number(localEvent?.type) === 4) {
          await functions.getDealPricing(dealData.dealID, dealData, setDealData);
          await functions.updateLeasePeriodPayment(dealData, setDealData);
        }

        if(element.length) element[0].disabled = false;
        if(element.length) element[0].focus();
        setRender(Math.random());

      }, localEvent.timeout);
      return () => clearTimeout(timer);

    } catch (error) {
      console.log(error);
      setRender(Math.random());
    }
  }, [localEvent])

  useEffect(() => {
    let activeFieldsLocal = {};
    Object.keys(activeFields).forEach(x => activeFieldsLocal[x] = false);
    setActiveFields(activeFieldsLocal);
  }, [render])


  const deleteOrder = () => {
    dispatch(setDrawer({
      open: true,
      content: <Modules.OrderDeleteDrawer
        ourDeal={dealData}
        setOurDeal={setDealData}
        history={history}
        route={route}
        isActive={isActive}
        onCancel={() => dispatch(setDrawer({ open: false, content: null }))}
      />
    }));
  }
  const copyOrder = () => {
    dispatch(setDrawer({
      open: true,
      width: "1000px",
      content: <Modules.OrderCopyDrawer
        onFinished={() => dispatch(setDrawer({ open: false, content: null }))}
        account={account}
        ourDeal={dealData}
        setOurDeal={setDealData}
        isActive={isActive}
      />
    }));
  }

  const actionArchive = async () => {
    if(window.confirm(`Archive deal\nAre you sure?`)) { 
      await Axios.post(`/sales/processStage/setProcessStage/`, { deal: dealData, options: 11 });
      await loadOrder(dealData.dealID);
    }
  }


  const approveOrDecline = () => {
    dispatch(setDrawer({ 
      open: true, 
      content: <Modules.OrderDeclineDrawer 
      ourDeal={dealData}
      setOurDeal={setDealData}
      history={history}
      onCancel={() => dispatch(setDrawer({ open: false, content: null }))} /> 
    }));
  }

  
  const tabs = [
    { label: `${processData.current.typeText} Details`, value: 0 },
    { label: "Additional Info", value: 1 },
    { label: "Documents", value: 2 },
    { label: "Install Checklist", value: 3 }]

  if (!dealLoaded) return <UILIB.Loading type={3} />

  if (submitModal) {
    return <Modules.OrderSubmitModal 
      history={history} 
      ourDeal={dealData}
      setOurDeal={setDealData}
      onCancel={() => setSubmitModal(false)} />
  }
  
  if (showApproveModal) {
    return <Modules.OrderApproveModal
      history={history} 
      ourDeal={dealData}
      setOurDeal={setDealData}
      onCancel={() => setShowApproveModal(false)} />
  }


  return <div className="flex-container row wrap mar-t10 mar-r5 mar-l5">
    <ToastContainer
      progressClassName="toastify"
      position="bottom-right"
      autoClose={5000}
      style={{ width: 500 }}
      hideProgressBar={false}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover />
    <div className="flex-container row wrap between width-100">
      <div className="flex-item start flex-grow-1">
        <UILIB.Button
          className="outline"
          value="Home"
          onClick={() => history.push('/')} />
      </div>
      <div className="flex-item end">
        {(dealData.status === 1 && dealData.processStage === 3 && userAccess.current.users.isAdmin) &&
          <UILIB.Button
            className="green"
            value={(isActive) ? 'Lock Order' : 'Amend Order'}
            onClick={() => setisActive(!isActive)} />}
      </div>
    </div>
    <Modules.DealHeader ourDeal={dealData} processData={processData} otherQuotes={dealData.otherDeals} />
    <div className="flex-item end width-100">
      {dealData.dynamicsOpportunityNumber && dealData.dynamicsOpportunityNumber !== "" && dealData.dynamicsOpportunityNumber !== "0" && <div>
        {(Number(isActive) === 1 && dealData.processStage === 1) &&
          <UILIB.Button
            disabled={!isActive}
            className="mar-b10"
            value="Clone Previous Order"
            title='Clones non customer specific data including parts and pricing from a previous order'
            onClick={() => copyOrder()} />}
        {(Number(isActive) === 1 && dealData.processStage === 1) &&
          <UILIB.Button
            disabled={!isActive || Object.values(dataLoaded).some(x => !Boolean(x))}
            className="mar-l10 mar-b10"
            value={`Submit`}
            onClick={async () => await checkErrors(true)} />}
        {(Number(isActive) === 1 && dealData.processStage === 1) &&
          <UILIB.Button
            disabled={!isActive}
            className="mar-b10 red"
            value={`DELETE Order`}
            title='Deletes the order and reopens the original quote'
            onClick={() => deleteOrder()} />}
        {(Number(isActive) === 1 && dealData.status === 1 && dealData.processStage === 2) &&
          <UILIB.Button
            disabled={!dealData.status === 1 || Object.values(dataLoaded).some(x => !Boolean(x))}
            className="mar-b10"
            value="Approve"
            onClick={() => setShowApproveModal(true)} />}
        {isActive && dealData.status === 1 && dealData.processStage === 2 &&
          <UILIB.Button
            disabled={!dealData.status === 1}
            className="mar-b10 red"
            value="Decline"
            onClick={() => approveOrDecline()} />}
      </div>}
    </div>
    <div className="flex-container row wrap between width-100">
      <h2 className="flex-item wrap start width-100">{processData.current.procStage}</h2>
      <div className="flex-item wrap start"><Modules.PatchCRM ourDeal={dealData} setOurDeal={setDealData} account={account} /></div>
      {<div className="flex-item wrap flex-grow-1 end">
        <UILIB.Button 
          className="orange"
          loading={dealSheetLoading}
          value={"Download Deal Sheet"}
          onClick={async () => await functions.generateDealSheet(dealData, setDealSheetLoading)} />
        {!userAccess.current.users.isSales && dealData.engStatus === 10 &&<div className="flex-item wrap end">
        <UILIB.Button 
          className="orange"
          loading={dealSheetLoading}
          value={"Archive"}
          onClick={async () => await actionArchive()} />
      </div>}  
    </div>}
    </div>
    <UILIB.SubNav
      tabs={tabs}
      currentTab={currentTab}
      outerClasses="mar-t15 mar-b15"
      onClick={(newId) => setCurrentTab(newId)}
      errored={{ option: 1, tabs: tabErrors }}
    />
    {currentTab === 0 &&
      <OrderDetailPage
        ourDeal={dealData}
        setOurDeal={setDealData}
        account={account}
        activeFields={activeFields}
        dealLoaded={dealLoaded}
        isActive={isActive}
        refreshData={refreshData}
        setRefreshData={setRefreshData}
        localEvent={localEvent}
        setLocalEvent={setLocalEvent}
        fieldWorkflowObj={fieldWorkflowObj}
        setFieldWorkflowObj={setFieldWorkflowObj}
        dealAddress={dealAddress}
        setDealAddress={setDealAddress}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        productErrors={productErrors}
        productConfigurationModal={productConfigurationModal}
        setProductConfigurationModal={setProductConfigurationModal}
        productTickedEvent={productTickedEvent}
        dataLoaded={dataLoaded} /> }
    {currentTab === 1 &&
      <AdditionalInfoPage
        dealLoaded={dealLoaded}
        ourDeal={dealData}
        setOurDeal={setDealData}
        isActive={isActive}
        processData={processData}
        setDealAddress={setDealAddress}
        additionalInfoErrors={additionalInfoErrors}
        fieldWorkflowObj={fieldWorkflowObj}
        setFieldWorkflowObj={setFieldWorkflowObj}
        refreshData={refreshData}
        dataLoaded={dataLoaded} /> }
    {currentTab === 2 &&
      <DocumentsPage
        dealLoaded={dealLoaded}
        ourDeal={dealData}
        setOurDeal={setDealData}
        isActive={isActive}
        dataLoaded={dataLoaded} /> }
    {currentTab === 3 &&
      <InstallChecklistTable
        route={location.pathname}
        localPermissions={localPermissions}
        permissionsObject={permissionsObject}
        permissions={permissions}
        location={location}
        dealId={dealData.dealID} /> }
  </div >
})
export default BlockOrders
