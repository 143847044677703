import { useState, useRef, Fragment } from "react";
import { useDispatch } from "react-redux";
import Axios from "classes/axios";
import UILIB from "components";
import { formatDateTime } from "classes/format";
import { setDrawer } from 'store/actions';
import AddExceptionsDrawer from "../drawer/addExceptionsDrawer";
import { getYesNo, actionOpenNotesDrawer, tableContainer } from '../functions'

export default function MIFExceptionsTable({ context }) {
  const headerData = [
    { label: '', value: 'exceptionID', type: 'delete', filtering: false, minWidth: 30, maxWidth: 30 },
    { label: "Customer Name", value: "customerName", type: "string", filtering: true, minWidth: 250, maxWidth: 250 },
    { label: "Serial Number", value: "serialNumber", type: "string", filtering: true, minWidth: 120, maxWidth: 120 },
    { label: "Collection Method", value: "collectionMethod", type: "string", filtering: true, minWidth: 120, maxWidth: 120 },
    { label: "Description", value: "description", type: "string", filtering: true, minWidth: 150, maxWidth: 150 },
    { label: "Exception Reason", value: "reason", type: "string", filtering: true, minWidth: 300, maxWidth: 300 },
    { label: "Exception Creation Date", value: "createdDateConvert", type: "date", filtering: true, minWidth: 150, maxWidth: 150 },
    { label: "Notes", value: "note", type: "string", filtering: false, minWidth: 60, maxWidth: 60, filterArray: getYesNo() },
  ];
    
  const dispatch = useDispatch();
  const entityData = useRef({});
  const defaultDialogue = { exceptionIds: [], count: 0, show: false };
  const [showDialogue, setShowDialoge] = useState(defaultDialogue);
  const [deleting, setDeleting] = useState(false);
  let orderBy = '';

  if(context.value === 1) {
    orderBy = 'impacted'
  }
  else if(context.value === 2) {
    orderBy = 'neverCommunicated'
  }

  const pageSelectedNumber = 50;
  const tablePageDefaults = { deleteEnabled: true, paging: { pages: [10, 20, 50, 100, 200], pageSelected: pageSelectedNumber, limit: pageSelectedNumber, offset: 0, orderBy: orderBy, orderDir: 'DESC' } };

  const [localRender, setLocalRender] = useState(false);
  const [remoteRender, setRemoteRender] = useState(false);
  const [renderMe, setRenderMe] = useState(true);


  const handleAddExceptionClicked = async () => {
    dispatch(
      setDrawer({
        open: true,
        content: <AddExceptionsDrawer setRemoteRender={setRemoteRender} onClose={exceptionsDrawerClose} />,
        width: '700px',
      })
    );
  };

  const exceptionsDrawerClose = async () => {
    dispatch(setDrawer({ open: false, content: null }));
    setRenderMe(!renderMe);
  };

  const tableDeleteException = async (event) => {
    setShowDialoge({ exceptionIds: event, count: event.length, show: true });
  };

  const actionDeleteException = async (exceptionIds) => {
    setDeleting(true);
    await Axios.delete(`/entities/mif_exceptions/mifExceptions`, { data: { exceptionIds: exceptionIds } });
    entityData.current.result = entityData.current.result.filter(x => !exceptionIds.map(m => Number(m)).includes(Number(x.id)));
    setShowDialoge(defaultDialogue);
    setDeleting(false);
    setLocalRender(!localRender);
  };

  async function constructTable(mifData) {

    const tableData = mifData.map(row => {
        const b_hasNotes = Boolean(row.notes && row.notes.length);
        const fn_notes = actionOpenNotesDrawer.bind(null, 'ViewNotesDrawer', row, dispatch, true, { get: remoteRender, set: setRemoteRender }, true);
        const notesColour = (b_hasNotes) ? 'colour colour background-6 red' : 'colour lightGrey';

        return {
            exceptionID: { value: row.id, raw: row.id },
            serialNumber: { value: row.serialNumber, raw: row.serialNumber },
            description: { value: row.description, raw: row.description },
            customerName: { value: row.customerName, raw: row.customerName },
            reason: { value: row.reason, raw: row.reason },
            createdDateConvert: { value: formatDateTime(row.createdDateConvert, "DD/MM/YYYY"), raw: row.createdDateConvert },
            collectionMethod: { value: row.collectionMethod, raw: row.collectionMethod },
            note: { value: tableContainer(fn_notes, 'icon-pencil', 'View Notes', notesColour, true), raw: b_hasNotes }
        };
    });
    return tableData;
  }

  const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken, source) => {

    try {
      const expandArray = (source === 'primary') ? ['notes'] : [];
      const queryLocal = (query !== null) ? query : '';
      const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
      const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';

      entityData.current = await Axios.get(`/entities/mif_exceptions/getSummary/?&$filter=${queryLocal}&$expand=${expandArray.map(x => x).join(' and ')}${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(api => api.data);

      if(!entityData.current || !entityData.current.result.length) return false;

      return { tableData: await constructTable(entityData.current.result), nonePaged: entityData.current.nonePaged };
    }
    catch(e) {
      console.log(`Exception: ${e}`);
    }
  }

  return (
    
    <Fragment>
        {showDialogue.show && <UILIB.MessageBox 
          header={'Delete Records'} 
          loading={deleting}
          text={`Click OK to DELETE the selected ${(showDialogue.count) > 1 ? 'Records' : 'Record'}`} 
          onConfirm={async () => await actionDeleteException(showDialogue.exceptionIds)} 
          onCancel={() => setShowDialoge({ ...showDialogue, show: false })} />}
      <UILIB.Paper className="width-100">
      {context.context === 'exceptions' && <div className="flex-item flex-grow-1 end">
        <UILIB.Button className="button height-100 mar-l20 mar-r5 mar-b15 small green" value='Add new exception' onClick={() => handleAddExceptionClicked()} />
      </div>}
        <UILIB.TableNew
          className="small"
          overflowX="auto"
          overflowY="hidden"
          header={headerData}
          localQuery={() => constructTable(entityData.current && entityData.current.result ? entityData.current.result : [])}
          localRender={[localRender]}
          remoteQuery={getTableData}
          remoteRender={[remoteRender]}
          defaultSettings={tablePageDefaults}
          deleteQuery={async (ev) => await tableDeleteException(ev)} />
      </UILIB.Paper>
    </Fragment>
  );
}
